import React, { useState, useEffect } from 'react';
import './ApproveSpotteds.css';
import { Button, Input, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { getAuthToken, getCommunityByName, getAllComunities, getAllSpottedsToApprove, approveSpotted, setSpottedToReview, rejectSpotted, getInstaQuotaByCommunity } from '../../utils/api_requests';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import { FaCheck, FaMobile  } from 'react-icons/fa';
import { MdMobileFriendly } from "react-icons/md";
import { Tooltip } from 'react-tooltip';

export default function ApproveSpotteds() {

    // =======================================================
    //                        SETUPS
    // =======================================================
    const [auth, setAuth] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [instagramQuota, setInstagramQuota] = useState(0);
    const [instagramQuotaTotal, setInstagramQuotaTotal] = useState(0);
    const [countAprovados, setCountAprovados] = useState(0);

    const navigate = useNavigate();

    const [refreshBoolean, setRefreshBoolean] = useState(false);
    const toggleRefreshBoolean = () => { setRefreshBoolean(!refreshBoolean) }
    function onRefreshSpottedsToApprove() {
        // Refresh list of spotteds to approve
        toggleRefreshBoolean();
    }


    // =======================================================

    // =======================================================
    //                         AUTH
    // =======================================================
    useEffect(() => {
        if (auth) {
            console.log("autenticado");
        }
    }, [auth]);

    function handleAuth() {
        getAuthToken(username, password).then(res => {
            if (res) {
                setAuth(true);
                setToken(res["token"]);
            }
            else {
                navigate('/')
            }
        })
            .catch(() => {
                navigate('/');
            });
    }

    // =======================================================

    // =======================================================
    //                 HANDLE ANY COMMUNITY
    // ======================================================= 

    const [currentCommunity, setCurrentCommunity] = useState();
    const [allCommunities, setAllCommunities] = useState([]);

    const [dropdownOpen, setDropdown] = useState(false);
    const [dropdown2Open, setDropdown2] = useState(false);
    const toggleDropdown = () => setDropdown(prevState => !prevState);
    const toggleDropdown2 = () => setDropdown2(prevState => !prevState);


    const [allSpottedsToApprove, setAllSpottedsToApprove] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    let rejectionTypes = [
        { type: "Ofensivo" },
        { type: "Discurso de ódio" },
        { type: "Hashtag" },
        { type: "Constrangedor" },
        { type: "Link" },
        { type: "Pequeno" },
        { type: "Grande" },
        { type: "Política" },
        { type: "Exposed" },
        { type: "Publi" },
        { type: "Outros" }
    ]

    function moveToNextIndex() {
        if (currentIndex + 1 >= allSpottedsToApprove.length) {
            setCurrentIndex(0);
        }
        else {
            setCurrentIndex(currentIndex + 1);
        }
    }
    function moveToPreviousIndex() {
        if (currentIndex - 1 < 0) {
            setCurrentIndex(allSpottedsToApprove.length - 1);
        }
        else {
            setCurrentIndex(currentIndex - 1);
        }
    }

    function refreshInstagramQuota(community) {
        getInstaQuotaByCommunity(community.id, token)
            .then(res => {

                setInstagramQuota(res['data']['0']['quota_usage_with_to_publish_spotteds']);
                setInstagramQuotaTotal(res['data']['0']['config']['quota_total']);
                setCountAprovados(0);
            })
            .catch((e) => { alert("Deu erro ao atualizar a quota do instagram") });
    }

    useEffect(() => {
        if (auth & (token != "")) {
            getAllComunities(token)
                // In case it finds a page with that name
                .then((res) => {

                    console.log(res[0]["name"]);
                    let list_allCommunities = [];
                    res.map((item, index) => {
                        list_allCommunities.push(item);
                    });
                    setAllCommunities(list_allCommunities);

                    if (res.length > 0) {
                        setCurrentCommunity(res[0]);
                    }
                })
                // In case it doesn't find a page with that name
                .catch((e) => console.log("deu erro"));
        }
    }, [auth, token]);

    // Gets all spotteds to approve in the current community
    useEffect(() => {
        // debugger;

        if (currentCommunity)
            if ((currentCommunity.id !== "") & auth & (token != "")) {

                getAllSpottedsToApprove(currentCommunity.id, token)
                    .then(res => {
                        setAllSpottedsToApprove(res);
                    })
                    .catch((e) => { alert("Deu erro ao carregar spotteds para aprovar") });

                refreshInstagramQuota(currentCommunity);
            }
    }, [currentCommunity, refreshBoolean]);





    function getFormattedDate(dateString) {
        const date = new Date(dateString);

        const day = date.getDate();
        const month = date.getMonth() + 1; // Mês começa do zero
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Função auxiliar para adicionar um zero à esquerda se o número for menor que 10
        const addLeadingZero = (num) => (num < 10 ? '0' + num : num);

        // Formatando a data e hora
        return `${addLeadingZero(day)}/${addLeadingZero(month)} as ${addLeadingZero(hours)}:${addLeadingZero(minutes)}`;
    };

    function getToolTipFormattedData(data) {
        return `Aprovado ${getFormattedDate(data['created_at'])} -- 
            
            ${data['explanation']}`
    }
    // =======================================================

    // =======================================================
    //                  EVALUATE SPOTTEDS
    // =======================================================

    function onApproveSpotted(spottedId) {
        if (allSpottedsToApprove.length > 0) {
            approveSpotted(spottedId, currentCommunity.id, token).then(res => {
                // Approved
                if (currentIndex + 1 >= allSpottedsToApprove.length) {
                    setCurrentIndex(0);
                }
                setAllSpottedsToApprove(allSpottedsToApprove.filter(item => item.id !== spottedId));
                setCountAprovados(countAprovados + 1);
            })
                .catch(() => {
                    alert("Erro ao Aprovar");
                });
        }
    }
    function onSetSpottedToReview(spottedId) {
        if (allSpottedsToApprove.length > 0) {
            setSpottedToReview(spottedId, currentCommunity.id, token).then(res => {
                // Approved
                if (currentIndex + 1 >= allSpottedsToApprove.length) {
                    setCurrentIndex(0);
                }
                setAllSpottedsToApprove(allSpottedsToApprove.filter(item => item.id !== spottedId));
                //setCountAprovados(countAprovados + 1);
            })
                .catch(() => {
                    alert("Erro ao deixar para revisão");
                });
        }
    }


    function onRejectSpotted(spottedId, reason) {
        if (allSpottedsToApprove.length > 0) {
            rejectSpotted(spottedId, currentCommunity.id, reason, token).then(res => {
                // Rejected
                if (currentIndex + 1 >= allSpottedsToApprove.length) {
                    setCurrentIndex(0);
                }
                setAllSpottedsToApprove(allSpottedsToApprove.filter(item => item.id !== spottedId));
            })
                .catch(() => {
                    alert("Erro ao Rejeitar");
                });
        }
    }


    // =======================================================


    return (
        <div>
            <Helmet>
                <title>Spotted</title>
                <meta name="description" content="Nossa vontade é conectar pessoas e criar uma comunidade unida, tendo como base o respeito, em primeiro lugar." />
            </Helmet>
            <div className="ApproveSpotteds">
                {auth ?
                    <div>
                        <Button onClick={(e) => { onRefreshSpottedsToApprove() }} color="primary" style={{ 'textAlign': 'center' }}> Recarregar</Button>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                            <DropdownToggle caret>
                                {currentCommunity ? currentCommunity.name : ""}
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    allCommunities.map((item, index) => {
                                        return (
                                            <DropdownItem key={index} onClick={(e) => { setCurrentCommunity(item); }}>{item.name}</DropdownItem>
                                        )
                                    })
                                }

                            </DropdownMenu>
                        </Dropdown>
                        <br /><br />
                    </div>
                    :
                    <div />
                }

                {auth ?
                    <div className="Box" style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center' }}>
                        <span style={{ 'fontSize': '20px', 'fontWeight': 'bold' }}> Aprovação de Spotteds </span>
                        {allSpottedsToApprove.length != 0 ? <center> {currentIndex + 1} de {allSpottedsToApprove.length} </center> : <center> 0 de 0 </center>}
                        <center> Instagram: {instagramQuota + countAprovados} / {instagramQuotaTotal} </center>



                        {allSpottedsToApprove.length != 0 ? <div style={{ 'marginBottom': '0px', 'fontSize': 'smaller', 'display': 'flex', 'flexDirection': 'row' }}>
                            <p style={{ 'marginRight': "15px" }}>{getFormattedDate(allSpottedsToApprove[currentIndex]['createdAt'])}</p>
                            {allSpottedsToApprove[currentIndex]['sentByApp'] === true ? <MdMobileFriendly 
                                //color='black'
                                size={20}
                                data-tooltip-id="mobileTooltip"
                                data-tooltip-content={"Envio pelo App"}
                                cursor={'pointer'}/> : null}
                            <Tooltip id="mobileTooltip" style={{ 'maxWidth': '90%' }}></Tooltip>

                            {allSpottedsToApprove[currentIndex]['openAiClassification'] !== null ? <FaCheck color="green"
                                size={20}
                                cursor={'pointer'}
                                data-tooltip-id="checkTooltip"
                                data-tooltip-content={getToolTipFormattedData(allSpottedsToApprove[currentIndex]['openAiClassification'])} /> : null}
                            <Tooltip id="checkTooltip" style={{ 'maxWidth': '90%' }}>
                            


                            </Tooltip>
                        </div> : null}


                        <span style={{ 'width': '100%', 'marginBottom': '5px', 'textAlign': 'center', 'border': '1px solid #000', 'minHeight': '300px' }}>
                            {allSpottedsToApprove.length === 0 ?
                                <span> <b>Sem textos a aprovar</b> </span>
                                :
                                <span> {allSpottedsToApprove[currentIndex]['description']} </span>
                            }
                        </span>
                        {allSpottedsToApprove.length === 0 ?
                            <div style={{ 'height': '43px' }} />
                            :
                            <div style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column ', 'alignItems': 'center', 'justifyContent': 'space-between', 'marginBottom': '15px' }}>
                                <div style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'justifyContent': 'space-between', 'marginBottom': '15px' }}>
                                    <Button onClick={(e) => { onApproveSpotted(allSpottedsToApprove[currentIndex]['id']) }} color="success" style={{ 'width': '90px', 'textAlign': 'center' }}> <i className="fa fa-check" style={{ 'fontSize': '20px', 'verticalAlign': 'middle' }} /> </Button>


                                    {/* <Button onClick={(e) => { onRejectSpotted(allSpottedsToApprove[currentIndex]['id']) }} color="danger" style={{ 'width': '90px', 'textAlign': 'center' }}> <i className="fa fa-times" style={{ 'fontSize': '20px', 'verticalAlign': 'middle' }} /> </Button> */}
                                    <Dropdown isOpen={dropdown2Open} toggle={toggleDropdown2} >
                                        <DropdownToggle caret color="danger" >
                                            <i className="fa fa-times" style={{ 'fontSize': '20px', 'verticalAlign': 'middle' }} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {rejectionTypes.map((item, index) => {
                                                return (<DropdownItem onClick={(e) => { onRejectSpotted(allSpottedsToApprove[currentIndex]['id'], index) }} > {item.type} </DropdownItem>)
                                            }
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button onClick={(e) => { moveToPreviousIndex() }} color="warning" style={{ 'width': '90px', 'textAlign': 'center' }}> <i className="fa fa-arrow-left" style={{ 'fontSize': '20px', 'verticalAlign': 'middle' }} /></Button>
                                    <Button onClick={(e) => { moveToNextIndex() }} color="warning" style={{ 'width': '90px', 'textAlign': 'center' }}> <i className="fa fa-arrow-right" style={{ 'fontSize': '20px', 'verticalAlign': 'middle' }} /></Button>

                                    {/* <UncontrolledDropdown group>
                                            
                                            <DropdownToggle
                                                caret
                                                color="primary"/>
                                                
                                            </UncontrolledDropdown> */}
                                </div>
                                {/* <Button onClick={(e) => { onApproveSpottedFacebook(allSpottedsToApprove[currentIndex]['id']) }} style={{ 'width': 'inherit', 'margin': "0 0 7px 0", 'textAlign': 'center', 'backgroundColor': "#4267B2" }}> <i className="fa fa-facebook-square" style={{ 'fontSize': '20px', 'verticalAlign': 'middle', 'color': 'white' }} /> </Button> */}
                                <Button onClick={(e) => { onSetSpottedToReview(allSpottedsToApprove[currentIndex]['id']) }} style={{ 'width': 'inherit', 'textAlign': 'center', 'background-color': "#f2d73d" }}> <i className="fa fa-trash" style={{ 'fontSize': '20px', 'verticalAlign': 'middle', 'color': "white" }} /> </Button>
                            </div>
                        }

                    </div>
                    :
                    <div className="authBox" style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center', 'paddingTop': '10px', 'paddingBottom': '10px', }}>
                        <span>username:</span>
                        <Input style={{ "marginTop": "5px", "marginBottom": "5px" }} type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                        <span>password:</span>
                        <Input type="password" style={{ "marginTop": "5px", "marginBottom": "20px" }} value={password} onChange={(e) => { setPassword(e.target.value) }} onKeyPress={(e) => { if (e.key === "Enter") { handleAuth() } }} />
                        <Button color="spotted" onClick={(e) => { handleAuth() }}> Enviar </Button>
                    </div>
                }
            </div>
        </div>
    )
}

import './App.css';
import {  BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home/Home';
import Spotted from './pages/Spotted/Spotted';
import NotFound from './pages/NotFound/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import ApproveSpotteds from './pages/ApproveSpotteds/ApproveSpotteds';
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/css/argon-design-system-react.css";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/:spotted_name" element={<Spotted />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/404" element={<NotFound />} />
          <Route exact path="/aprovacao" element={<ApproveSpotteds />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;

import React from 'react'
import underConstructionImg from '../under_construction.svg'
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';

export default function NotFound() {

    const navigate = useNavigate();
    function goSpottedUnicamp(){
        navigate('/Unicamp');
    }


    return (
        <div>
            <Helmet>
                <title>Spotted - 404</title>
                <meta name="description" content="Nossa vontade é conectar pessoas e criar uma comunidade unida, tendo como base o respeito, em primeiro lugar." />
            </Helmet>
            <div style={{"width":"100%", "height":"100vh", "display":"flex", "flexDirection":"column", "alignItems":"center", "justifyContent":"center"}}>
                <h5> Ir para <a href="javascript:void(0)" onClick={goSpottedUnicamp}><b> Spotted Unicamp</b> </a></h5>
                <img src={underConstructionImg} width="300px" height="300px" />
                <h4 style={{'width':'300px', 'textAlign':'center'}}> <b> Página não encontrada... </b></h4>
            </div>
        </div>
    )
}
